let translation = 
{
    "translation": {
        "key": "en",
        "mobile": {
            "landscape": "Please rotate your device into portrait mode.",
            "info": {
                "header": "Do you need help with your configuration?",
                "text": "If you have any questions about the mymotion configurator, please do not hesitate to contact us.<br>Contact us: +49 60 50 / 97 10 12"
            }
        },
        "cookie": {
            "ok": "OK",
            "message": "STRAUSS is using cookies on this page. You can find more information on our <a target=\"_blank\" href=\"https://www.strauss.com/de/en/Legal_information/Mymotionkonfigurator_Datenschutz\">data protection page</a>."
        },
        "splashscreen": {
            "link": "CUSTOMIZE YOUR PERSONAL E.S. MYMOTION",
            "code": {
                "placeholder": "CI-MY12345",
                "continue": "Continue",
                "info": "You already saved a design?<br>Enter your code here."
            }
        },
        "logos": {
            "standard": "Default",
            "stitchedBlack": "Embroidered in black",
            "stitchedWhite": "Embroidered in white"
        },
        "gender": {
            "female": "Women",
            "male": "Men"
        },
        "tags": {
            "motion2020": "Motion 2020",
            "motion": "Motion",
            "pants": "Trousers",
            "jackets": "Jackets"
        },
        "notfound": {
            "title": "PAGE NOT FOUND",
            "subtitle": "The page you are trying to access is unavailable. You might have mistyped the address or the page was relocated."
        },
        "editscreen": {
            "inspire": "Get inspired",
            "orderlist": "Order list",
            "orderlistView": "View order list",
            "products": "Products",
            "colors": "Your colours",
            "logos": "Your logo",
            "baseColor": "Base color",
            "contrastColor": "Contrast color",
            "straussLogo": "Strauss logo",
            "save": "Save mymotion",
            "chooseproduct": "Choose products",
            "selection": "Selection",
            "incVat": "with VAT",
            "pricingFrom": "from {{amount}} items: {{price}} with VAT",
            "pricingFromNoVat": "from {{amount}} items: {{price}} without VAT",
            "pricingInfo": "Buy more, save more - with larger quantities you benefit from a lower unit price.",
            "minAmount": "The minimum order quantity for a product is {{amount}} pieces and can be achieved by combining different sizes as well as the respective women's and men's versions.",
            "incVentilation": "With zipper vents at the back of both thighs",
            "excVentilation": "Without zipper vents at the back of both thighs",
            "invVentilationShort": "With zipper",
            "excVentilationShort": "Without zipper",
            "ventilationInfo": "<b>Zipper ventilation on the back</b><br>Your individual e.s. mymotion 2020 CI products can be manufactured with or without zip ventilation on the rear thighs.",
            "configProduct": "Configure",
            "basics": "DISCOVER MATCHING BASICS",
            "addToOrderList": "Add to order list",
            "addToOrderListAlreadyOn": "Already added",
            "personalize": "Personalize",
            "changeProduct": "Change product",
            "productDetails": "Product details",
            "showAdditionalInfo": "View product description",
            "closeAdditionalInfo": "Close product description",
            "logo": {
                "header": "YOUR LOGO",
                "intro": "Your logo as a distinctive highlight. Decide on a personalization type and upload up to two logos.Then select the desired placement area and logo size. If you do not yet have a suitable logo file, select the appropriate option. Our team of experts will help you personally after the ordering process. If you do not want logo personalization on the products, simply add them to your order list without logos.",
                "info": "Notice",
                "infoText": "The numbered areas on the product show the options for logo placement. You will receive a preview of what your logo will look like on the product from our team of experts as a PDF during the consultation following your order request.",
                "upload": {
                    "header": "Logo upload",
                    "info": "Upload up to 2 different logos to personalize your clothing.",
                    "drag": "Drag your logo here to upload.",
                    "or": "OR",
                    "browse": "Browse files",
                    "fileformats": "Supported file formats: <b>.EPS</b>, <b>.AI</b> <b>.PDF</b>, <b>.CDR</b> or <b>.JPG</b > - Maximum file size: <b>19MB</b>",
                    "rights": "By uploading a logo, you confirm not to violate name, trademark and copyrights as well as other rights of third parties and laws. All legal information can be found <a target=\"_blank\" href=\"https://www.strauss.com/de/en/Legal_information/Mymotionkonfigurator_Datenschutz\">here.</a>"
                },
                "type": {
                    "print": "Transfer printing",
                    "stitch": "Direct embroidery"
                },
                "transferInfo": "The personalisation all-rounder:<br>Durable film print for almost all textiles up to 900 cm² - special colours and patterns possible.<br><br>The higher the order quantity, the lower the price for your personalisation.<br>(Prices depend on the logo size in cm²)",
                "directInfo": "Particularly elegant and durable: Your personalisation element is sewn on directly - a wide range of fonts, stitching patterns and colours available.<br>(Prices depend on the logo size in cm²)",
                "add": "Place first logo",                
                "addAdditional": "Place second logo",
                "existing": "Already placed logos",
                "placement": "Placement",
                "placementEmpty": "Choose placement",
                "placementHelp": "Click a face on the left or select a face below to add a logo.",
                "noLogo": "Continue without logo",
                "sizeEmpty": "Select size",
                "place": "Place logo",
                "placeFirst": "Place logo and add to order list",
                "area": "Placement",
                "front": "in front",
                "back": "at the back",
                "side": "on the side",
                "uploadFirst": "Upload company logo",
                "uploadSecond": "Upload second logo"
            },
            "additionalProductInfos": {
                "knee": "<b>Additional standard through the use of knee pads</b><br>Working kneeling - that means enormous strain on your knees! There is a risk of chronic illnesses and injuries from objects on the ground. Protect your knees from these dangers.<br><br><b>This is how knee protection is made:</b><br>These pants meet the requirements of \"knee protection for working in a kneeling position\" type 2, performance level 1 according to DIN EN 14404:2004+A1:2010 in combination with the certified e.s. knee pads.",
                "zipper": "<b>Zippered ventilation on back thighs</b><br>Your individual e.s. mymotion 2020 CI products can be made with or without zip ventilation on the rear thighs.",
                "jacketzipper": ""
            }
        },
        "save": {
            "header": "SAVE YOUR MYMOTION",
            "intro": "You can save your configuration to continue later or to share it. To do so, simply copy the code below.",
            "choices": "YOUR PREVIOUS SELECTION",
            "code": "YOUR INDIVIDUAL CODE",
            "copyCode": "Copy code",
            "info": "We will save your configuration for the next 60 days. You can access it at any time during this period.",
            "copied": "The code was copied into the clipboard!"
        },
        "inspirationscreen": {
            "header": "GET INSPIRED BY OUR WORKWEAR DESIGNERS",
            "use": "APPLY THIS COMBINATION"
        },
        "orderscreen": {
            "backToOverview": "Back to overview",
            "header": "Good choice! These are your items",
            "subHeader": "Please select the number and size of each item and complete the order list.",
            "headerEmpty": "Too bad...",
            "subHeaderEmpty": "Your order list is still empty.",
            "priceWithoutVat": "{{price}} without VAT",
            "priceWithVat": "{{price}} with VAT",
            "minimumOrder": "The minimum order quantity for a product is {{pieces}} pieces and can be achieved by combining different sizes as well as the respective women's and men's versions.",
            "amount": "Amount",
            "size": "Size",
            "addSize": "Add size",
            "removeSize": "Remove size",
            "nextStep": "Next step",
            "sum": {
                "totalNoVat": "Total",
                "shipping": "Shipping cost",
                "free": "free",
                "additionalVat": "+ VAT",
                "totalWithVat": "Total cost",
                "withVat": "with VAT",
                "additionalCosts": "plus personalization costs"
            },
            "personalized": {
                "consultingHeader": "Advice on personalized<br>mymotion products",
                "callHeader": "We'll call you",
                "callText": "We will contact you as soon as possible about the type, position and price of your personalization"
            },
            "basics": {
                "header": "Would you like suitable basics for your configuration?",
                "text": "Together with your request, our customer advisors will receive a note that you would like to add suitable basics to your order. In a personal consultation, items will be suggested to you that are suitable for your personal mymotion configuration."
            },
            "inspectColors": {
                "header": "Would you like to check your color selection in advance?",
                "text": "We offer free shipping of fabric samples with all MyMotion colors. In this way you can compare the different color gradations with your current selection."
            },
            "inspectSize": {
                "header": "Would you like to check your size selection in advance?",
                "text": "Not sure what sizes your team needs? We would be happy to send you different size samples free of charge. These are not yet delivered with your individual configuration."
            },
            "getInspired": "Get inspired",
            "addProducts": "Choose products"
        },
        "progress": {
            "orderlist": "Order List",
            "customerdata": "Customer Data",
            "overview": "Order Overview"
        },
        "current": {
            "header": "Your current configuration",
            "uploadedLogo": "Uploaded logo",
            "save": "Save and share your configuration"
        },
        "additionalProducts": {
            "header": "Add additional products",
            "addNow": "Add now",
            "price": "<b>from {{price}}</b> (without VAT)",
            "fromAmount": "from {{pieces}} Pc"
        },
        "customerscreen": {
            "contact": {
                "orderFor": "Order for:",
                "customerNumber": "Customer number (optional)",
                "firstName": "First name",
                "lastName": "Last name",
                "companyName": "Company name",
                "companyContact": "Contact person",
                "country": "Country",
                "zipCode": "Postcode",
                "city": "Location",
                "address": "Address",
                "title": "Title",
                "occupation": "Occupation",
                "sector": "Professional sector",
                "email": "Email address",
                "phone": "Phone number",
                "comment": "Comment (optional)",
                "titleFemale": "Ms",
                "titleMale": "Mr"
            },
            "orderFor": {
                "company": "Company",
                "practice": "Practice",
                "private": "Private"
            },
            "backToOrderscreen": "Back to order list"
        },
        "finalscreen": {
            "backToCustomerscreen": "Back to customer data",
            "header": "Last check - everything ok?",
            "subHeader": "Please check all data before submitting your request.",
            "furtherActions": {
                "header": "HOW TO CONTINUE",
                "step1Header": "Finalization with our customer service",
                "step1Text": "Our customer service will contact you shortly in order to implement your wishes in the best possible way.",
                "step2Header": "Start of production",
                "step2Text": "Your order will be forwarded to our production and commissioned. Your personal mymotion products are now being produced.",
                "step3Header": "Shipping of your mymotion products",
                "step3Text": "Your order is now on its way to you."
            },
            "policyAccept": "I agree",            
            "eula": "I have read the <a target=\"_blank\" href=\"{{link}}\">General Terms and Conditions</a> for the \"CI MYMOTION CONFIGURATOR\" and accept them.",
            "send": "Submit",
            "error": "There was an error submitting your order. Please try again and contact us at +49 60 50 / 97 10 12 if this error persists."
        },
        "postscreen": {
            "title": "THANK YOU FOR YOUR ORDER",
            "summary": "We will get back to you as soon as possible. You will receive a confirmation of receipt and a summary of your order by e-mail shortly. <br><br>If you have any questions, please do not hesitate to send us an email:<br>auftrag-mymotion@strauss.de",
            "toShop": "Back to the online shop",
            "nextSteps": "NEXT STEPS"
        },
        "exitscreen": {
            "title": "EXIT CONFIGURATOR",
            "text": "Do you really want to exit the configurator? You can save your current configuration with your creator code below:",
            "cancel": "CANCEL",
            "quit": "QUIT"
        },
        "linkfooter": {
            "home": "STRAUSS",
            "impressum": "Imprint",
            "eula": "Terms and conditions",
            "gdpr": "Data protection",
            "osplatform": "OS-Platform",
            "cookie": "Cookie setting"
        },
        "logo": {
            "title": "CI CONFIGURATOR"
        },
        "misc": {
            "back": "Back",
            "continue": "Continue",
            "capture": "Download Image"
        },
        "country": {
            "title": "Notice",
            "text": "You chose another country as delivery address. We updated the currency and VAT accordingly.",
            "close": "Continue"
        },
        "errors": {
            "network": "Failed to connect to server!",
            "notfound": "This code was not found!",
            "unauthorized": "You are not authorized to access this resource."
        }
    }
};

export default translation;